import { cloneElement, createElement } from 'react';

import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';
import NoMatch from 'Route/NoMatch';

import Check from '../component/Check';

const RouterComponent_getSortedItems = (args, callback) =>
    callback(...args).map(({ component, permissions = [], themes = [], ...rest }) => {
        const [type] = args;

        if (type !== SWITCH_ITEMS_TYPE) {
            return {
                ...rest,
                component: createElement(Check, { permissions, themes }, cloneElement(component)),
            };
        }

        return {
            ...rest,
            component: cloneElement(component, {
                render: (props) =>
                    createElement(
                        Check,
                        { permissions, themes, fallback: createElement(NoMatch) },
                        component.props.render(props)
                    ),
            }),
        };
    });

export default {
    'Component/Router/Component': {
        'member-function': {
            getSortedItems: RouterComponent_getSortedItems,
        },
    },
};
