import { CUSTOMER_ACCOUNT } from 'Component/Header/Header.config';
import MyAccountQuery from 'Query/MyAccount.query';
import { MyAccountContainer } from 'Route/MyAccount/MyAccount.container';
import { ONE_MONTH_IN_SECONDS } from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { scrollToTop } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field, prepareQuery } from 'Util/Query';
import { executePost, getErrorMessage } from 'Util/Request';

export const AUTHORITIES = 'authorities';

export const UPDATE_CUSTOMER_AUTHORITIES = 'UPDATE_CUSTOMER_AUTHORITIES';

/** @namespace Permissions/Store/Permissions/Action/updateCustomerAuthorities */
const updateCustomerAuthorities = (authorities) => ({
    type: UPDATE_CUSTOMER_AUTHORITIES,
    authorities,
});

const MyAccount_getCustomerPermissionsQuery = () =>
    new Field('getUserPermissions').addField(new Field('list').setAlias('authorities'));

const MyAccountStore_initialState = (args, callback) => ({
    ...callback(...args),
    authorities: [],
});

const MyAccount_reducer = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { type, authorities = [] } = action;

    if (type !== UPDATE_CUSTOMER_AUTHORITIES) {
        return originalUpdatedState;
    }

    return { ...originalUpdatedState, authorities };
};

const MyAccount_logout = (args, callback) => {
    const [_, dispatch] = args;

    callback(...args);

    BrowserDatabase.deleteItem(AUTHORITIES);
    dispatch(updateCustomerAuthorities([]));
};

const MyAccount_requestCustomerData = (args, callback) => {
    const [dispatch] = args;

    const authoritiesQuery = MyAccountQuery.getCustomerPermissionsQuery();

    const authorities = BrowserDatabase.getItem(AUTHORITIES) || [];

    if (authorities.length) {
        dispatch(updateCustomerAuthorities(authorities));
    }

    callback(...args);

    return executePost(prepareQuery([authoritiesQuery])).then(
        ({ getUserPermissions: { authorities = [] } = {} }) => {
            if (authorities) {
                dispatch(updateCustomerAuthorities(authorities));
                BrowserDatabase.setItem(authorities, AUTHORITIES, ONE_MONTH_IN_SECONDS);
            }
        },
        (error) => dispatch(showNotification('error', getErrorMessage(error), null))
    );
};

const MyAccount_handleCustomerDataOnInit = (args, callback, instance) => {
    const [dispatch] = args;

    if (isSignedIn()) {
        instance.requestCustomerData(dispatch);
    }

    callback(...args);
};

const MyAccount__construct = (args, callback, instance) => {
    const { updateMeta, toggleOverlayByKey } = instance.props;

    instance.state = {
        ...MyAccountContainer.navigateToSelectedTab(instance.props),
        isEditingActive: false,
    };

    if (!isSignedIn()) {
        toggleOverlayByKey(CUSTOMER_ACCOUNT);
    }

    updateMeta({ title: __('My account') });

    instance.redirectIfNotSignedIn();
    instance.updateBreadcrumbs();
    scrollToTop();
};

export default {
    'Store/MyAccount/Reducer': {
        function: MyAccount_reducer,
    },
    'Store/MyAccount/Reducer/getInitialState': {
        function: MyAccountStore_initialState,
    },
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            requestCustomerData: MyAccount_requestCustomerData,
            handleCustomerDataOnInit: MyAccount_handleCustomerDataOnInit,
            logout: MyAccount_logout,
        },
    },
    'Query/MyAccount': {
        'member-function': {
            getCustomerPermissionsQuery: MyAccount_getCustomerPermissionsQuery,
        },
    },
    'Route/MyAccount/Container': {
        'member-function': {
            __construct: MyAccount__construct,
        },
    },
};
