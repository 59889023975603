import { ProductListQuery as AdvoxBasicProductListQuery } from 'AdvoxBasicQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace AdvoxStrigob2b/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends AdvoxBasicProductListQuery {
    _getCustomPriceField() {
        return new Field('custom_price_incl_tax').addField('currency').addField('value');
    }

    _getCustomPriceExclTaxField() {
        return new Field('custom_price_excl_tax').addField('currency').addField('value');
    }

    _getMinimalPriceFields() {
        return [
            this._getDiscountField(),
            this._getFinalPriceField(),
            this._getFinalPriceExclTaxField(),
            this._getRegularPriceField(),
            this._getRegularPriceExclTaxField(),
            this._getDefaultPriceField(),
            this._getDefaultFinalPriceField(),
            this._getDefaultFinalPriceExclTaxField(),
            this._getCustomPriceField(),
            this._getCustomPriceExclTaxField(),
        ];
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isCart = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            withBulkPackaging = false,
        } = this.options;

        // Basic fields returned always
        const fields = ['id', 'sku', 'name', 'type_id', 'stock_status', 'stock_label', this._getStockItemField(), this._getProductQtyInSource()];

        if (!isCart) {
            fields.push(this._getAdvoxLabelsField());
        }

        if (isCart) {
            fields.push(this._getCategoriesField(), this._getPriceRangeField());
        }

        // Additional fields, which we want to return always, except when it is on PLP
        if (!isPlp) {
            fields.push(
                this._getPriceRangeField(),
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                'special_price',
                this._getTierPricesField()
            );

            // to all products, except when it is on PLP (non-variants)
            if (!isVariant) {
                fields.push(this._getUrlRewritesFields());

                // except when it is on cart
                if (!isCart) {
                    fields.push(this._getReviewCountField(), this._getRatingSummaryField());
                }
            }
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (isPlp && !isVariant) {
            fields.push(
                this._getPriceRangeField(),
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                'special_from_date',
                'special_to_date',
                'special_price',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        if (!isVariant && withBulkPackaging) {
            fields.push('bulk_packaging');
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push('url', this._getCustomizableProductFragment(), this._getRelatedProductsByAttributeField());

            // if variants are not needed
            if (!noVariants) {
                fields.push(this._getConfigurableProductFragment(), this._getBundleProductFragment());
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                'omnibus_lowest_price',
                'delivery_time',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getCustomizableProductFragment(),
                this._getCategoriesField()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField(),
                    this._getRelatedProductsByAttributeField()
                );
            }
        }

        return fields;
    }
}

export default new ProductListQuery();
